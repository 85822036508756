























import Vue from 'vue'

import AuthService from '@/services/AuthService'
import LoginForm from '../components/LoginForm.vue'

export default Vue.extend({
  name: 'Login',
  components: {
    LoginForm,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      hasError: false,
      message: 'Invalid username or password.',
    }
  },
  methods: {
    login(username: string, password: string): void {
      AuthService.login(username, password)
        .then(() => {
          this.$router.push('/home')
        })
        .catch(() => {
          this.hasError = true
        })
    },
  },
})
