<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <v-img
          class="text-center logo"
          :src="require('../../assets/logo.svg')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-form @submit="handleLogin">
            <v-card-text>
              <v-text-field
                v-model="username"
                id="username"
                label="Username"
                name="username"
                prepend-icon="mdi-account"
                type="text"
              ></v-text-field>

              <v-text-field
                v-model="password"
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary">
                Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginForm",
  data: () => {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    handleLogin: function(e) {
      e.preventDefault();
      this.$emit("login", this.username, this.password);
    }
  }
};
</script>

<style scoped>
.logo {
  width: 300px;
}
</style>
